<template>
  <div>
    <v-row class="mb-6">
      <v-col>
        <v-card>
          <v-card-title class="mb-4">Search Submitted Invoices</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" md="2">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y eager min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Invoice Month"
                        v-model="invoiceMonth"
                        class="header-inputs flex-grow-0"
                        readonly
                        outlined
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="submitLoading"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="invoiceMonth" type="month" :disabled="submitLoading"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="5" class="d-flex">
                  <v-select
                    v-model="selectedUnits"
                    :items="units"
                    label="Unit(s)"
                    item-text="label"
                    item-value="index"
                    return-object
                    required
                    hide-details="auto"
                    class="mb-6 mr-1"
                    outlined
                    dense
                    multiple
                    :disabled="submitLoading"
                  >
                    <template v-slot:selection="{ item, index }">
                      <!-- Show 'All Units' chip only once if it is selected -->
                      <v-chip v-if="selectedUnits.some(unit => unit.label === 'All Units') && index === 0">
                        <span>All Units ({{ selectedUnits.length - 1 }})</span>
                      </v-chip>

                      <!-- Show up to 4 selected units chips if 'All Units' is not selected -->
                      <v-chip v-if="index < 4 && !selectedUnits.some(unit => unit.label === 'All Units')">
                        <span>{{ item.label }}</span>
                      </v-chip>

                      <!-- Show the count of other selected units if more than 4 are selected -->
                      <span
                        v-if="index === 4 && !selectedUnits.some(unit => unit.label === 'All Units')"
                        class="text-grey text-caption align-self-center"
                      >
                        (+{{ selectedUnits.length - 4 }} others)
                      </span>
                    </template>
                  </v-select>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="mb-6">{{ icons.mdiAlertCircleOutline }}</v-icon>
                    </template>
                    <span
                      >Out of the selected units, only units that have at least one ticket in the selected month will
                      show in the results.</span
                    >
                  </v-tooltip>
                </v-col>

                <v-col cols="6" class="pt-0">
                  <v-btn
                    class="mr-3"
                    color="primary"
                    @click="getInvoiceData()"
                    :disabled="submitLoading || selectedUnits.length < 1"
                  >
                    <template v-if="submitLoading">
                      <v-progress-circular :size="22" :width="2" indeterminate color="white"></v-progress-circular>
                    </template>
                    <template v-else>Search</template>
                  </v-btn>
                  <v-btn text small color="primary" @click="resetSearch()" :disabled="submitLoading">Reset</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <SubmittedInvoicesTable
      :submittedInvoices="invoiceData"
      :userData="userData"
      :jdcAdmin="jdcAdmin"
      :selectedInvoiceMonth="formattedInvoiceMonth"
      @updateInvoiceData="updateInvoiceData"
    />
  </div>
</template>

<script>
import { mdiAlertCircleOutline, mdiDotsVertical, mdiSquareEditOutline } from '@mdi/js'
import axios from 'axios'
import firebase from 'firebase'
import lodash from 'lodash'
import moment from 'moment-timezone'
import SubmittedInvoicesTable from '../components/SubmittedInvoicesTable.vue'

export default {
  page: {
    name: 'Contractor Balance Sheet',
  },
  components: {
    SubmittedInvoicesTable,
  },
  data() {
    return {
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiAlertCircleOutline,
      },
      invoiceMonth: null,
      formattedInvoiceMonth: null,
      selectedUnits: [],
      units: [],
      submitLoading: false,
      userData: null,
      jdcAdmin: false,
      invoiceData: [],
    }
  },
  methods: {
    getUnits: async function () {
      try {
        let allUnits = []
        let index = 0

        if (!this.jdcAdmin) {
          // not a jd&c admin so just get this orgs units
          const doc = await firebase.firestore().collection('orgs').doc(sessionStorage.selectedOrg).get()

          if (doc.exists) {
            doc.data().units.forEach(unit => {
              allUnits.push({
                orgDocID: sessionStorage.selectedOrg,
                unit: unit,
                orgName: doc.data().name,
                index: index,
                label: unit,
              })
              index++
            })
            this.units = allUnits
            this.units.sort((a, b) => a.unit.localeCompare(b.unit))
            this.units.unshift({
              orgDocID: null,
              unit: null,
              orgName: null,
              index: index,
              label: 'All Units',
            })
          }
        } else {
          // jd&c admin so get all orgs units
          const orgsQuerySnapshot = await firebase.firestore().collection('orgs').get()
          for (const doc of orgsQuerySnapshot.docs) {
            let org = doc.data()
            if (org.name !== 'JD&C Services' && org.name !== 'Test Company' && org.name !== 'Test City') {
              org.units.forEach(unit => {
                allUnits.push({
                  orgDocID: doc.id,
                  unit: unit,
                  orgName: org.name,
                  index: index,
                  label: `${unit} (${org.name})`,
                })
                index++
              })
            }
          }
          this.units = allUnits
          this.units.sort((a, b) => a.unit.localeCompare(b.unit))
          this.units.unshift({
            orgDocID: null,
            unit: null,
            orgName: null,
            index: index,
            label: 'All Units',
          })
        }
      } catch (err) {
        console.log(`Error getting units: ${err}`)
        this.$toasted.show('Error getting units', {
          duration: null,
          keepOnHover: true,
          type: 'error',
        })
      }
    },
    getInvoiceData: async function () {
      try {
        this.submitLoading = true

        if (this.invoiceMonth === null || this.selectedUnits.length < 1) {
          this.showError('Please select an invoice month and at least one unit')
          this.submitLoading = false
          return
        }

        // organize units based on org
        let unitsByOrg = []
        this.selectedUnits.forEach(unit => {
          // exclude the all units option
          if (unit.unit !== null) {
            const orgIndex = unitsByOrg.findIndex(x => x.orgDocID === unit.orgDocID)
            if (orgIndex === -1) {
              unitsByOrg.push({ orgDocID: unit.orgDocID, orgName: unit.orgName, units: [unit.unit] })
            } else {
              unitsByOrg[orgIndex].units.push(unit.unit)
            }
          }
        })

        // set up date range once for all queries
        const firstOfMonth = moment.tz(`${this.invoiceMonth}-01`, 'YYYY-MM-DD', 'America/Edmonton').startOf('month')
        const lastDay = moment.tz(`${this.invoiceMonth}-01`, 'YYYY-MM-DD', 'America/Edmonton').endOf('month')

        // convert to Firestore Timestamps (but in UTC) for querying
        const firestoreTimestampStart = firebase.firestore.Timestamp.fromDate(firstOfMonth.utc().toDate())
        const firestoreTimestampEnd = firebase.firestore.Timestamp.fromDate(lastDay.utc().toDate())

        // get ticket data for all selectedUnits and create map of unique ticket nums for each unit, organize by invoiced and non-invoiced ticketNums
        let ticketList = []
        let uniqueTicketNums = {}
        try {
          const unitLabelsString = this.selectedUnits
            .filter(unit => unit.unit !== null)
            .map(unit => unit.unit)
            .join(',')

          const response = await axios.post(
            'https://wilardapi.azurewebsites.net/api/Tickets/contractor-balance-sheet',
            {
              unitID: unitLabelsString,
              production: 'Yes,No,N12,D15,T0,L12',
              commision: '0.15',
              dateA: firstOfMonth.tz('America/Edmonton').format('YYYY-MM-DD HH:mm:ss'),
              dateB: lastDay.tz('America/Edmonton').format('YYYY-MM-DD HH:mm:ss'),
              customer: 0,
              operations: ' ',
            },
            {
              headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
            },
          )

          ticketList = response.data

          ticketList.forEach(ticket => {
            if (
              !uniqueTicketNums[ticket.unitID] &&
              new Date(ticket.ticketDate) >= firstOfMonth &&
              new Date(ticket.ticketDate) <= lastDay
            ) {
              if (!ticket.contractorInvoiced) {
                uniqueTicketNums[ticket.unitID] = { notInvoiced: [ticket.ticketNum], invoiced: [] }
              } else {
                uniqueTicketNums[ticket.unitID] = { notInvoiced: [], invoiced: [ticket.ticketNum] }
              }
            } else {
              // check if the ticketNum has been added already for this unit, if not add it to the units array of ticketNums
              if (
                !(
                  uniqueTicketNums[ticket.unitID].notInvoiced.includes(ticket.ticketNum) ||
                  uniqueTicketNums[ticket.unitID].invoiced.includes(ticket.ticketNum)
                ) &&
                new Date(ticket.ticketDate) >= firstOfMonth &&
                new Date(ticket.ticketDate) <= lastDay
              ) {
                if (!ticket.contractorInvoiced) {
                  uniqueTicketNums[ticket.unitID] = {
                    notInvoiced: [...uniqueTicketNums[ticket.unitID].notInvoiced, ticket.ticketNum],
                    invoiced: uniqueTicketNums[ticket.unitID].invoiced,
                  }
                } else {
                  uniqueTicketNums[ticket.unitID] = {
                    notInvoiced: uniqueTicketNums[ticket.unitID].notInvoiced,
                    invoiced: [...uniqueTicketNums[ticket.unitID].invoiced, ticket.ticketNum],
                  }
                }
              }
            }
          })
        } catch (err) {
          console.log('Error getting ticket data for the selected units: ', err)
        }

        // create all queries in parallel for getting submitted invoices
        const queryPromises = unitsByOrg.map(async org => {
          const baseQuery = firebase
            .firestore()
            .collection('orgs')
            .doc(org.orgDocID)
            .collection('invoices')
            .where('invoiceMonth', '>=', firestoreTimestampStart)
            .where('invoiceMonth', '<', firestoreTimestampEnd)

          if (org.units.length <= 10) {
            // we can just use one "in" where filter
            const querySnapshot = await baseQuery.where('unit', 'in', org.units).get()
            return querySnapshot.docs.map(doc => ({
              ...doc.data(),
              mstFormattedInvoiceDate: moment(doc.data().invoiceDate.toDate())
                .tz('America/Edmonton')
                .format('MMM DD, YY'),
              mstFormattedSubmittedDate: moment(doc.data().submittedDate.toDate())
                .tz('America/Edmonton')
                .format('MMM DD, YY'),
              docID: doc.id,
              orgName: org.orgName,
              orgDocID: org.orgDocID,
              submitted: true,
              invoicePdfUrl: doc.data().pdfURLs?.generated || null,
              invoiceEmailed: doc.data().invoiceEmailed !== undefined ? doc.data().invoiceEmailed : true,
              invoicedTickets:
                uniqueTicketNums[doc.data().unit] && uniqueTicketNums[doc.data().unit].invoiced
                  ? uniqueTicketNums[doc.data().unit].invoiced.length
                  : 0,
              notInvoicedTickets:
                uniqueTicketNums[doc.data().unit] && uniqueTicketNums[doc.data().unit].notInvoiced
                  ? uniqueTicketNums[doc.data().unit].notInvoiced.length
                  : 0,
              receivedByAdmin: doc.data().receivedByAdmin !== undefined ? doc.data().receivedByAdmin : true,
            }))
          } else {
            // the "in" where filter only supports up to 10 array items so we have to split the array up and do seperate queries
            const unitChunks = []
            for (let i = 0; i < org.units.length; i += 10) {
              unitChunks.push(org.units.slice(i, i + 10))
            }

            const chunkPromises = unitChunks.map(chunk => baseQuery.where('unit', 'in', chunk).get())

            const snapshots = await Promise.all(chunkPromises)
            return snapshots.flatMap(querySnapshot =>
              querySnapshot.docs.map(doc => ({
                ...doc.data(),
                docID: doc.id,
                orgName: org.orgName,
                orgDocID: org.orgDocID,
                submitted: true,
                invoicePdfUrl: doc.data().pdfURLs?.generated || null,
                invoiceEmailed: doc.data().invoiceEmailed !== undefined ? doc.data().invoiceEmailed : true,
                invoicedTickets:
                  uniqueTicketNums[doc.data().unit] && uniqueTicketNums[doc.data().unit].invoiced
                    ? uniqueTicketNums[doc.data().unit].invoiced.length
                    : 0,
                notInvoicedTickets:
                  uniqueTicketNums[doc.data().unit] && uniqueTicketNums[doc.data().unit].notInvoiced
                    ? uniqueTicketNums[doc.data().unit].notInvoiced.length
                    : 0,
                receivedByAdmin: doc.data().receivedByAdmin !== undefined ? doc.data().receivedByAdmin : true,
              })),
            )
          }
        })

        // wait for all queries to complete
        const invoiceArrays = await Promise.all(queryPromises)
        let invoices = invoiceArrays.flat()

        // go through selected units and add any that don't have a submitted invoice for the month
        // so that they are visible in the table as not submitted
        this.selectedUnits.forEach(unit => {
          // exclude the all units option and any units that don't have any tickets at all for the selected month
          if (
            unit.unit !== null &&
            ticketList.some(
              x => x.unitID == unit.unit && new Date(x.ticketDate) >= firstOfMonth && new Date(x.ticketDate) <= lastDay,
            )
          ) {
            const invoiceIndex = invoices.findIndex(x => x.unit === unit.unit && x.orgDocID === unit.orgDocID)
            if (invoiceIndex === -1) {
              invoices.push({
                orgDocID: unit.orgDocID,
                orgName: unit.orgName,
                unit: unit.unit,
                submitted: false,
                pdfURLs: { generated: null },
                docID: null,
                invoiceDate: null,
                submittedDate: null,
                totals: {
                  ticketCount: null,
                  hours: null,
                  total: null,
                },
                invoiceEmailed: false,
                invoicedTickets:
                  uniqueTicketNums[unit.unit] && uniqueTicketNums[unit.unit].invoiced
                    ? uniqueTicketNums[unit.unit].invoiced.length
                    : 0,
                notInvoicedTickets:
                  uniqueTicketNums[unit.unit] && uniqueTicketNums[unit.unit].notInvoiced
                    ? uniqueTicketNums[unit.unit].notInvoiced.length
                    : 0,
                receivedByAdmin: false,
              })
            }
          }
        })

        //sort invoices by ascending unit
        invoices.sort((a, b) => a.unit.localeCompare(b.unit))

        this.invoiceData = invoices

        // set formatted invoice month for display
        const [year, month] = this.invoiceMonth.split('-')
        const date = new Date(year, month - 1, 1)
        const options = { year: 'numeric', month: 'long' }
        this.formattedInvoiceMonth = new Intl.DateTimeFormat('en-US', options).format(date)

        this.submitLoading = false
      } catch (err) {
        this.submitLoading = false
        console.log(`Error getting invoice data: ${err}`)
        this.$toasted.show('Error getting invoice data', {
          duration: null,
          keepOnHover: true,
          type: 'error',
        })
      }
    },
    resetSearch: function () {
      this.invoiceData = []
      this.selectedUnits = []
      // get current month in the format YYYY-MM
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const formattedDate = `${year}-${month}`
      this.invoiceMonth = formattedDate
    },
    updateInvoiceData: function (data) {
      // this method is to be used when we need to emit an invoice data update from the child component
      this.invoiceData = data.updatedInvoiceData
    },
    showError: function (message) {
      this.$toasted.show(message, {
        duration: '9000',
        keepOnHover: true,
        type: 'error',
      })
    },
  },
  watch: {
    async userData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.jdcAdmin = this.userData && this.userData.email === 'admin@jdcservices.ca' ? true : false
        await this.getUnits()
      }
    },
    selectedUnits(newVal, oldVal) {
      if (newVal !== oldVal) {
        const oldValAllUnitsIndex = oldVal.findIndex(x => x.unit === null)
        const newValAllUnitsIndex = newVal.findIndex(x => x.unit === null)

        if (oldValAllUnitsIndex === -1 && newValAllUnitsIndex !== -1) {
          // the all units option was selected
          this.selectedUnits = this.units
        } else if (oldValAllUnitsIndex !== -1 && newValAllUnitsIndex !== -1 && newVal.length !== this.units.length) {
          // this means the all units option was already selected and now a unit was deselected so remove the all units option from the selected units
          let selectedUnitsCopy = lodash.cloneDeep(this.selectedUnits)
          selectedUnitsCopy.splice(newValAllUnitsIndex, 1)
          this.selectedUnits = selectedUnitsCopy
        } else if (
          oldValAllUnitsIndex !== -1 &&
          newValAllUnitsIndex === -1 &&
          newVal.length === this.units.length - 1
        ) {
          // this means the all units option was specifically deselected so remove all selected units
          this.selectedUnits = []
        } else if (
          oldValAllUnitsIndex === -1 &&
          newValAllUnitsIndex === -1 &&
          newVal.length === this.units.length - 1
        ) {
          // this means that the all units wasn't specifically selected but all units were manually selected
          this.selectedUnits = this.units
        }
      }
    },
  },
  async mounted() {
    this.userData = this.$store.state.userData

    // get current month in the format YYYY-MM
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const formattedDate = `${year}-${month}`
    this.invoiceMonth = formattedDate
  },
}
</script>
