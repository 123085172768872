var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-6"},[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"mb-4"},[_vm._v("Search Submitted Invoices")]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","eager":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"header-inputs flex-grow-0",attrs:{"label":"Invoice Month","readonly":"","outlined":"","dense":"","hide-details":"auto","disabled":_vm.submitLoading},model:{value:(_vm.invoiceMonth),callback:function ($$v) {_vm.invoiceMonth=$$v},expression:"invoiceMonth"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"type":"month","disabled":_vm.submitLoading},model:{value:(_vm.invoiceMonth),callback:function ($$v) {_vm.invoiceMonth=$$v},expression:"invoiceMonth"}})],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"5"}},[_c('v-select',{staticClass:"mb-6 mr-1",attrs:{"items":_vm.units,"label":"Unit(s)","item-text":"label","item-value":"index","return-object":"","required":"","hide-details":"auto","outlined":"","dense":"","multiple":"","disabled":_vm.submitLoading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.selectedUnits.some(function (unit) { return unit.label === 'All Units'; }) && index === 0)?_c('v-chip',[_c('span',[_vm._v("All Units ("+_vm._s(_vm.selectedUnits.length - 1)+")")])]):_vm._e(),(index < 4 && !_vm.selectedUnits.some(function (unit) { return unit.label === 'All Units'; }))?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.label))])]):_vm._e(),(index === 4 && !_vm.selectedUnits.some(function (unit) { return unit.label === 'All Units'; }))?_c('span',{staticClass:"text-grey text-caption align-self-center"},[_vm._v(" (+"+_vm._s(_vm.selectedUnits.length - 4)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedUnits),callback:function ($$v) {_vm.selectedUnits=$$v},expression:"selectedUnits"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-6"},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icons.mdiAlertCircleOutline))])]}}])},[_c('span',[_vm._v("Out of the selected units, only units that have at least one ticket in the selected month will show in the results.")])])],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","disabled":_vm.submitLoading || _vm.selectedUnits.length < 1},on:{"click":function($event){return _vm.getInvoiceData()}}},[(_vm.submitLoading)?[_c('v-progress-circular',{attrs:{"size":22,"width":2,"indeterminate":"","color":"white"}})]:[_vm._v("Search")]],2),_c('v-btn',{attrs:{"text":"","small":"","color":"primary","disabled":_vm.submitLoading},on:{"click":function($event){return _vm.resetSearch()}}},[_vm._v("Reset")])],1)],1)],1)],1)],1)],1)],1),_c('SubmittedInvoicesTable',{attrs:{"submittedInvoices":_vm.invoiceData,"userData":_vm.userData,"jdcAdmin":_vm.jdcAdmin,"selectedInvoiceMonth":_vm.formattedInvoiceMonth},on:{"updateInvoiceData":_vm.updateInvoiceData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }